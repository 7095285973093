import { defineStore } from "pinia";
// import { processSQLiteFile } from "@/utils/SQLiteToIndexedDB";
import { useEcomStore } from "@/stores/apps/eCommerce";
import { useUsersStore } from "@/stores/users";
import { FacebookLogin } from '@capacitor-community/facebook-login';


export const useAuthStore = defineStore({
    id: "auth",
    state: () => ({
        // initialize state from local storage to enable user to stay logged in
        appSession: JSON.parse(localStorage.getItem("auth.appSession") ?? "{}"),
        userSession: JSON.parse(localStorage.getItem("auth.userSession") ?? "{}"),
        userCompany: JSON.parse(localStorage.getItem("auth.userCompany") ?? "{}"),
        returnUrl: null,
    }),
    actions: {
        async validate(force: boolean = false) {
            if (!this.appSession) return false;
            let parsedDatetime = new Date(Date.parse(this.appSession.expires));
            let actualDatetime = new Date();
            let datetimesDiff = parsedDatetime.getTime() - actualDatetime.getTime();
            // console.log("validate() => datetimesDiff: ", datetimesDiff);
            if (datetimesDiff / (1000 * 60) <= 0) {
                await this.logout();
                return false;
            } else {
                // TODO: Lógica de expiración del token:
                /* else if (((datetimesDiff / (1000 * 60)) < 5 && (datetimesDiff / (1000 * 60)) > 0) || force) {
                    const baseUrl = useRuntimeConfig().public.apiBase;
                    return await fetchAppWrapper.post(`${baseUrl}/api-auth/token-refresh/`, { 'refresh': this.appSession.refresh })
                    .then((response) => {
                        this.appSession.refresh = response.refresh
                        this.appSession.expires = new Date(actualDatetime.getTime() + (24 * 60 * 60 * 1000));
                        return true;
                    })
                    .catch(() => {
                        this.userSession = null;
                        return false;
                    })
                */
                return true;
            }
        },
        async appLogin() {
            try {
                const baseUrl = useRuntimeConfig().public.apiBase;
                const email = useRuntimeConfig().public.apiUser;
                const password = useRuntimeConfig().public.apiPass; // N/A

                let isExpired = false;
                if (Object.keys(this.appSession).length > 0) {
                    const dateExpiration = new Date(this.appSession.expires)
                    isExpired = dateExpiration <= new Date();
                }
                // TODO: NO hacerlo por expiración al api-auth, sino hacerlo con expiración con el refresh-token
                if (Object.keys(this.appSession).length === 0 || isExpired) {
                    let responseAuth = await fetchAppWrapper.post(
                        `${baseUrl}/api-auth/`,
                        { email, password }
                    );
                    localStorage.setItem("auth.appSession", JSON.stringify(responseAuth));
                    this.appSession = responseAuth;
                }
            } catch (e) {
                return Promise.reject(e);
            }
        },
        async userLogin(email: string, password: string, coordinates: string) {
            const baseUrl = useRuntimeConfig().public.apiBase;
            try {
                const response = await fetchAppWrapper.post(
                    `${baseUrl}/api-auth/`, 
                    { email, password, coordinates }
                );
                // console.info("response: ", response);
                if (response) {
                    this.userSession = response;
                    // store user details and jwt in local storage to keep user logged in between page refreshes
                    localStorage.setItem("auth.userSession", JSON.stringify(response));
                    await useUsersStore().setCreditCards(this.userSession?.user?.thirdparty?.credit_card || []);
                    await useStoresStore().getStores();
                    if (this.isCompanyUser){
                        // TODO: Traer el dato de company-info desde user-info de una vez, para evitar esta consulta:
                        const companyInfo = await fetchWrapper.get(this.userSession.user.user_info.company)
                        this.userCompany = {
                            id: this.userSession.user.user_info.company_id,
                            name: companyInfo.company_name,
                            light_logo: companyInfo.light_logo,
                            short_light_logo: companyInfo.short_light_logo,
                            primary_color: companyInfo.primary_color,
                            extra_fields: companyInfo.extra_fields
                        }
                        localStorage.setItem("auth.userCompany", JSON.stringify(this.userCompany));
                    }
                    navigateTo("/");
                }
            } catch (e) {
                return Promise.reject(e);
            }
        },
        async userRegisterPetition(payload:any) {
            const baseUrl = useRuntimeConfig().public.apiBase;
            try {
                const response = await fetchAppWrapper.post(
                    `${baseUrl}/base/users/register/`,
                    payload
                );
                console.info("response register: ", response);
                if (response) {
                    await this.userLogin(payload.email, payload.password, payload.coordinates)
                }
            } catch (e) {
                return Promise.reject(e);
            }
        },
        async userUpdatePetition(payload: Object, id: Number, redirectToHome: boolean = false) {
            const baseUrl = useRuntimeConfig().public.apiBase;
            try {
                const response = await fetchAppWrapper.put(
                    `${baseUrl}/base/users/${id}/`,
                    payload
                );
                // console.info("response: ", response);
                if (response) {
                    let result = {
                        user: {}
                    }
                    let userKeys = JSON.parse(localStorage.getItem("auth.userSession") ?? "{}")
                    userKeys.user = response
                    userKeys.user.user = response.user_info
                    userKeys.user.thirdparty = response.thirdparty
                    this.userSession = userKeys;
                    // store user details and jwt in local storage to keep user logged in between page refreshes
                    localStorage.setItem("auth.userSession", JSON.stringify(userKeys));
                    if (redirectToHome){
                        navigateTo("/");
                    }
                }
            } catch (e) {
                return Promise.reject(e);
            }
        },
        async userRegister(payload: any, redirectToHome: boolean = false) {
            const baseUrl = useRuntimeConfig().public.apiBase;
            console.info(payload);
            // console.info(payload.email);
            let userPayload = {}
            if ((!payload.gmail && !payload.facebook) || payload.id) {
                userPayload = {
                    username: `${payload.email ? payload.email.trim().toLowerCase() : ''}`,
                    email: `${payload.email.trim().toLowerCase()}`,
                    password: `${payload.password ? payload.password : null}`,
                    repeat_password: `${payload.password ? payload.password : null}`,
                    app_sections_users: [],
                    social_auth: false,
                    // TODO: tipo identificación variable
                    identification_type: payload.identificationType? `${payload.identificationType}` : "C", 
                    nit: `${payload.identificationNumber.trim()}`,
                    social_reason: `${payload.socialReason ? payload.socialReason.trim().toUpperCase() : ""}`,
                    firstName: `${payload.firstName ? payload.firstName.trim().toUpperCase() : ""}`,
                    middleName: `${payload.middleName ? payload.middleName.trim().toUpperCase() : ""}`,
                    firstSurname: `${payload.firstSurname ? payload.firstSurname.trim().toUpperCase() : ""}`,
                    secondSurname: `${payload.secondSurname ? payload.secondSurname.trim().toUpperCase() : ""}`,
                    born_date: `${payload.bornDate}`,
                    ind_tipo_tercero: payload.identificationType != "N" ? 1 : 2,
                    contact: `${payload.firstName ?? "".trim()} ${payload.middleName ?? "".trim().toUpperCase()}`,
                    address: `${payload.address ? payload.address.trim().toUpperCase() : ""}`,
                    phone: `${payload.phone ? payload.phone.trim() : ""}`,
                    // email: `${payload.email.trim().toLowerCase()}`,
                    cellphone: `${payload.cellphone.trim()}`,
                    genre: `${payload.genre ? payload.genre : ''}`,
                }

            } else {
                userPayload = {
                    username: `${payload.username ? payload.username : payload.email ? payload.email.trim().toLowerCase() : ''}`,
                    email: `${payload.email.trim().toLowerCase()}`,
                    password: `${payload.sub ? payload.sub : payload.id_facebook}`,
                    repeat_password: `${payload.sub ? payload.sub : payload.id_facebook}`,
                    app_sections_users: [],
                    sub: `${payload.sub ? payload.sub : null}`, 
                    id_facebook: `${payload.id_facebook ? payload.id_facebook : null}`,
                    social_auth: true,
                    data_social_auth: `${payload.gmail ? 'GMAIL' : 'FACEBOOK'}`,
                    picture: `${payload.picture? payload.picture : null }`
                }
            }

            try {
                if (!payload.id) {
                    if (userPayload.social_auth) {
                        await useUsersStore().getUserForEmail(userPayload.email).then(async (data) => {
                            // console.error("data:", data, "userPayload:", userPayload)
                            if (data && data.results && data.results.length > 0) {
                                this.userUpdatePetition(userPayload, data.results[0].id, false)
                                await this.userLogin(userPayload.email, userPayload.password, payload.coordinates)
                            } else {
                                await this.userRegisterPetition(userPayload)
                            }
                        }).catch((e) => {
                            return Promise.reject(e);
                        })
                    } else if (!userPayload.social_auth) {
                        await this.userRegisterPetition(userPayload)
                    }
                } else {
                    await this.userUpdatePetition(userPayload, payload.id, redirectToHome)
                }
            } catch (e) {
                return Promise.reject(e);
            }
        },
        async logout(type: string | undefined | null = null) {
            // console.log("pasa por logout!");
            // Log out from Facebook:
            try {
                const result = await FacebookLogin.getCurrentAccessToken()
                if (result.accessToken) {
                    await FacebookLogin.logout();
                }
            } catch(e){
                // console.info("Is not Facebook authenticated")
            }
            this.userSession = null;
            if (type !== "Unauthorized") {
                localStorage.clear();
                // localStorage.setItem("colorScheme", "");
                // localStorage.setItem("darkMode", "");
                // localStorage.setItem("menuMode", "");
            }
            localStorage.removeItem("auth.appSession");
            localStorage.removeItem("auth.userSession");
            localStorage.removeItem("stores.allStores");
            localStorage.removeItem("stores.selectedStore");
            await useEcomStore().deleteCart("auth.logout()");
            useStoresStore().cleanStores();
            await navigateTo("/auth/login");
        },
        async setUserThirdpartyCreditCards(creditCards:any){
            if (this.userSession.user && this.userSession.user.thirdparty){
                this.userSession.user.thirdparty.credit_card = creditCards;
                localStorage.setItem("auth.userSession", JSON.stringify(this.userSession));
            }
        },
        async setUserPaymentsGatewayCustomerId(id:string){
            this.userSession.user.thirdparty.id_customer_payments_gateway = id;
            localStorage.setItem("auth.userSession", JSON.stringify(this.userSession));
        }
    },
    getters: {
        isLogged(state) {
            return state.userSession && Object.keys(state.userSession).length > 0
                ? true
                : false;
        },
        userName(state) {
            return state.userSession && Object.keys(state.userSession).length > 0
                ? `${state.userSession.name}`
                : "";
        },
        userLastName(state) {
            return state.userSession && Object.keys(state.userSession).length > 0
                ? `${state.userSession.last_name1} ${state.userSession.last_name2}`
                : "";
        },
        isThirdpartyFilled(state) {
            const validation = state.userSession &&
                state.userSession.user && 
                state.userSession.user.thirdparty  &&
                state.userSession.user.thirdparty.first_name &&
                state.userSession.user.thirdparty.first_surname &&
                state.userSession.user.thirdparty.cellphone && state.userSession.user.thirdparty.cellphone.length > 7 &&
                state.userSession.user.thirdparty.email && state.userSession.user.thirdparty.email.length > 5 &&
                state.userSession.user.thirdparty.identification_type &&
                state.userSession.user.thirdparty.born_date ? 
                true : false; 
            return validation;
        },
        hasPaymentMethod(state) {
            return state.userSession &&
                    state.userSession.user && 
                    state.userSession.user.thirdparty  &&
                    state.userSession.user.thirdparty.credit_card && 
                    state.userSession.user.thirdparty.credit_card.length > 0 ? 
                    true : false; 
        },
        getUserGroups(state){
            return state.userSession && 
                    state.userSession.user && 
                    state.userSession.user.groups_detail.map((group:any) => group.name) || [];
        },
        isCompanyUser(state){
            return state.userSession && 
                    state.userSession.user && 
                    state.userSession.user.user_info && 
                    state.userSession.user.user_info.company ? 
                    true : false;
        }
    },
});
