import { defineStore } from 'pinia';
import { useAuthStore } from '@/stores/auth';

let foundStores = []
try {
	foundStores = JSON.parse(localStorage.getItem("stores.allStores") ?? "[]")
} catch (e) {
	foundStores = []
}

const foundSelectedStore = JSON.parse(localStorage.getItem("stores.selectedStore") || '{}');
const foundParameters = JSON.parse(localStorage.getItem("stores.parameters") || "{}");
const foundRestrictions = JSON.parse(localStorage.getItem("stores.restrictions") || "{}");
const foundCoordinates = JSON.parse(localStorage.getItem("stores.coordinates") || "{}");
const foundMaxItems = JSON.parse(localStorage.getItem("stores.selectedStore") || "{}")?.qty_max ?? 5;

export const useStoresStore = defineStore({
    id: "stores",
    state: () => ({
        stores: {
			loading: false,
			data: foundStores.length > 0 ? foundStores : []
		},
		selectedStore: foundSelectedStore,
		parameters: foundParameters,
		restrictions: foundRestrictions,
		userCoordinates: foundCoordinates,
		maximumItems: foundMaxItems,
    }),
    actions: {
        async getStores() {
			this.stores.data = useAuthStore().userSession.nearby_stores;
			localStorage.setItem("stores.allStores", JSON.stringify(useAuthStore().userSession.nearby_stores));
		},
		async refreshNearbyStores(coordinates:object) {
			const baseUrl = useRuntimeConfig().public.apiBase;
			this.stores.loading = true;
			try {
				// console.log("antes de near_stores")
				if (coordinates && coordinates.lat && coordinates.lon){
					const data = await fetchWrapper.post(`${baseUrl}/api/v1/management/stores/near_stores/`, 
						{ 
							lat: coordinates.lat, 
							lon: coordinates.lon,
                            company: useAuthStore().isCompanyUser ? useAuthStore().userCompany.id.toString() : ''
						}
					);
					// console.log("data: ", data);
					this.stores.loading = false;
					return data.results;
				} else {
					this.stores.loading = false;
					return [];
				}
			} catch (error) {
				this.stores.loading = false;
				throw error;
			}
		},
		async setSelectedStore(store:object) {
			this.selectedStore = store;
			localStorage.setItem("stores.selectedStore", store ? JSON.stringify(store) : '');
			try {
				const companyColor = store.company_info.primary_color
				localStorage.setItem("primaryColor", companyColor)
			} catch (e){
				// console.error("Error setting theme color: ", e);
			}

			if (!store){
				localStorage.setItem("stores.parameters", "{}");
				localStorage.setItem("stores.restrictions", "{}");
				return;
			}

			this.maximumItems = store.qty_max;
			
			// Fetch the client configuration information:
			const baseUrl = useRuntimeConfig().public.apiBase;
			if (store && store.company){
				let response = await fetchAppWrapper.get(`${baseUrl}/api/v1/management/companies/${store.company}/`);
				if (response){
					const extra_fields = response.extra_fields ?? {};
					this.parameters = extra_fields.parameters ? extra_fields.parameters : {};
					this.restrictions = extra_fields.restrictions ? extra_fields.restrictions : {};
					localStorage.setItem("stores.parameters", JSON.stringify(this.parameters));
					localStorage.setItem("stores.restrictions", JSON.stringify(this.restrictions));
				}
			}
		},
		setUserCoordinates(coordinates:object){
			this.userCoordinates = coordinates;
			console.log("setUserCoordinates: ", coordinates)
			localStorage.setItem("stores.coordinates", JSON.stringify(coordinates));
		},
		async setNearbyStores(stores:object){
			this.stores.data = stores;
			localStorage.setItem("stores.allStores", JSON.stringify(stores));
		},
		cleanStores(){
			this.stores.data = [];
            this.selectedStore = {};
		}
    },
    getters: {
        getSelectedStore(state) {
			// console.log("pasa por getSelectedStore", state)
            return state.selectedStore;
        },
		async getNearbyStores(state) {
			//return state.stores.data.filter((store: { isNearby: boolean; }) => store.isNearby === true);
			return state.stores.data && state.stores.data.length > 0 ? state.stores.data.sort((a:any, b:any) => a.currentDistance - b.currentDistance) : state.stores.data ?? [];
		},
		getUserCoordinates(state) {
			// console.log("getUserCoordinates:", state.userCoordinates)
			//return state.stores.data.filter((store: { isNearby: boolean; }) => store.isNearby === true);
			return state.userCoordinates || {};
		},
		getPackagingOption(state) {
			if (state.selectedStore){
				let packageItem = state.selectedStore?.company_info?.extra_fields?.parameters?.packaging
				if (packageItem && packageItem.length > 0){
					// TODO: Por ahora es solo un ítem [0], pero pueden ser varios tipos de bolsas
					let alreadyAdded = useEcomStore().findProductOnCart(packageItem[0].ean);
					packageItem[0].added = alreadyAdded ? true : false;
					// packageItem[0].item = !alreadyAdded ? useEcomStore().fetchProduct(packageItem[0].ean, true) : alreadyAdded;
                    return packageItem[0]; // <-- { added: true/false }
                } else {
					return { added: null };
				}
			} else {
				return { added: null };
			}
		}
    },
});
