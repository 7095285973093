// product shop list
export type Products = {
    pk?: number | undefined;
    id: string | number | undefined;
    barcode: string | number | undefined;
    readed_code: string | number | undefined;
    image?: string;
    name: string;
    rating?: number;
    discount?: number;
    discount_card?: number;
    tax?: number;
    price?: number;
    offer_price?: number;
    offer_card_price?: number;
    categories?: string[];
    age_restriction?: boolean;
    date?: number;
    created?: Date;
    qty?: number;
    unit?: string;
    sku?: string;
    added?: string;
};

// product lite (to send to backend)
export type ProductsLite = {
    id: string | number | undefined;
    ean: string | number | undefined;
    readed_code: string | number | undefined;
    discount?: number;
    price?:number;
    qty?: number;
};


// checkout-cart billing address
export type Address = {
    id?: string | any | Date;
    name: string;
    destination: string;
    building: string;
    city: string;
    state: string;
    phone: string;
    isDefault: boolean;
};

// product reviews list
export type Reviews = {
    id: string | number | undefined;
    rating: number;
    review: string;
    date: Date | string;
    profile: {
        avatar: string;
        name: string;
        status: boolean;
    };
};

export interface ProductStateProps {
    invoiceId?: number | null;
    products?: Products[];
    cart?: Products[] | any;
    price?: number;
    subtotal?: any;
    taxes?: any;
    total?: number;
    total_qty?: number;
    discount?: number;
    discount_card?: number;
    hasRestriction: boolean;
    
    activeCard?: object;
    paymentMethod?: String | null;
    sortBy?: String;
    error?: object | string | null;
}

export const recategorizeData = [
    { group: "ALIMENTOS", category: "ACEITES" },
    { group: "ALIMENTOS", category: "AGUAS" },
    { group: "ALIMENTOS", category: "APERITIVOS Y MEZCLADORES" },
    { group: "ALIMENTOS", category: "AREPAS" },
    { group: "ALIMENTOS", category: "AROMATICAS Y TE" },
    { group: "ALIMENTOS", category: "AVENAS LIQUIDAS" },
    { group: "ALIMENTOS", category: "AVENAS SOLIDAS" },
    { group: "ALIMENTOS", category: "BASES PARA COCTELES" },
    { group: "ALIMENTOS", category: "BEBIDA NUTRICIONAL" },
    { group: "ALIMENTOS", category: "BEBIDAS" },
    { group: "ALIMENTOS", category: "BEBIDAS CALIENTES PANADERIA FRESCA" },
    { group: "ALIMENTOS", category: "BEBIDAS DE TE" },
    { group: "ALIMENTOS", category: "BEBIDAS ENERGIZANTES" },
    { group: "ALIMENTOS", category: "BEBIDAS FRIAS PANADERIA FRESCA" },
    { group: "ALIMENTOS", category: "BEBIDAS HIDRATANTES" },
    { group: "ALIMENTOS", category: "BEBIDAS LACTEAS" },
    { group: "ALIMENTOS", category: "BEBIDAS LINEA SALUDABLE" },
    { group: "ALIMENTOS", category: "CAFE" },
    { group: "ALIMENTOS", category: "CALDOS, SOPAS, CREMAS Y BASES" },
    { group: "ALIMENTOS", category: "CEREALES" },
    { group: "ALIMENTOS", category: "CHOCOLATES DE MESA" },
    { group: "ALIMENTOS", category: "COMIDAS LISTAS CHARCUTERIA" },
    { group: "ALIMENTOS", category: "COMIDAS RAPIDAS PREPARADAS" },
    { group: "ALIMENTOS", category: "COMPOTAS Y CEREALES BEBE" },
    { group: "ALIMENTOS", category: "CONDIMENTOS" },
    { group: "ALIMENTOS", category: "CONDIMENTOS" },
    { group: "ALIMENTOS", category: "CONFITERIA Y CHOCOLATES" },
    { group: "ALIMENTOS", category: "DERIVADOS QB" },
    { group: "ALIMENTOS", category: "DULCES TIPICOS" },
    { group: "ALIMENTOS", category: "DULCES Y FRUTAS EN CONSERVA" },
    { group: "ALIMENTOS", category: "ENCURTIDOS CHARCUTERIA" },
    { group: "ALIMENTOS", category: "ENDULZANTES" },
    { group: "ALIMENTOS", category: "GALLETERIA" },
    { group: "ALIMENTOS", category: "GASEOSAS" },
    { group: "ALIMENTOS", category: "GELATINAS, FLANES Y PUDINES" },
    { group: "ALIMENTOS", category: "GRANOLAS" },
    { group: "ALIMENTOS", category: "GRANOS" },
    { group: "ALIMENTOS", category: "HARINAS" },
    { group: "ALIMENTOS", category: "HELADOS" },
    { group: "ALIMENTOS", category: "HIERBAS Y AROMATICAS" },
    { group: "ALIMENTOS", category: "HUEVOS" },
    { group: "ALIMENTOS", category: "INFUSIONES" },
    { group: "ALIMENTOS", category: "JUGOS" },
    { group: "ALIMENTOS", category: "JUGOS Y ZUMOS" },
    { group: "ALIMENTOS", category: "LECHES DE SOYA" },
    { group: "ALIMENTOS", category: "LECHES LIQUIDAS" },
    { group: "ALIMENTOS", category: "LECHES PROCESADAS" },
    { group: "ALIMENTOS", category: "MALTAS" },
    { group: "ALIMENTOS", category: "MANI Y NUECES" },
    { group: "ALIMENTOS", category: "MANTEQUILLAS" },
    { group: "ALIMENTOS", category: "MARGARINAS" },
    { group: "ALIMENTOS", category: "MASA PARA AREPAS" },
    { group: "ALIMENTOS", category: "MATERIA PRIMA CHARCUTERIA" },
    { group: "ALIMENTOS", category: "MATERIA PRIMA PANADERIA FRESCA" },
    { group: "ALIMENTOS", category: "MATERIA PRIMAS" },
    { group: "ALIMENTOS", category: "MERCADO" },
    { group: "ALIMENTOS", category: "MERCADOS Y ANCHETAS" },
    { group: "ALIMENTOS", category: "MERMELADAS Y OTROS ESPARCIBLES" },
    { group: "ALIMENTOS", category: "MEZCLA SALUDABLE" },
    { group: "ALIMENTOS", category: "MIELES Y ALIMENTOS NATURISTAS" },
    { group: "ALIMENTOS", category: "MODIFICADORES DE LECHE" },
    { group: "ALIMENTOS", category: "MUNDO MEXICANO" },
    { group: "ALIMENTOS", category: "MUNDO SALUDABLE" },
    { group: "ALIMENTOS", category: "OTROS CONGELADOS" },
    { group: "ALIMENTOS", category: "OTROS REFRIGERADOS" },
    { group: "ALIMENTOS", category: "PANADERIA FRESCA" },
    { group: "ALIMENTOS", category: "PANADERIA INDUSTRIAL" },
    { group: "ALIMENTOS", category: "PASABOCAS CONGELADOS" },
    { group: "ALIMENTOS", category: "PASABOCAS LISTOS" },
    { group: "ALIMENTOS", category: "PASTAS" },
    { group: "ALIMENTOS", category: "PLATO PRINCIPAL COMIDAS PREPARADAS" },
    { group: "ALIMENTOS", category: "PLATOS LISTOS CONGELADOS" },
    { group: "ALIMENTOS", category: "PONQUES" },
    { group: "ALIMENTOS", category: "POSTRES REFRIGERADOS" },
    { group: "ALIMENTOS", category: "QUESOS AUTOSERVICIO" },
    { group: "ALIMENTOS", category: "QUESOS BLANDOS CHARCUTERIA" },
    { group: "ALIMENTOS", category: "QUESOS CHARCUTERIA" },
    { group: "ALIMENTOS", category: "QUESOS MADURADOS CHARCUTERIA" },
    { group: "ALIMENTOS", category: "QUESOS REFRIGERADOS" },
    { group: "ALIMENTOS", category: "REFRESCOS" },
    { group: "ALIMENTOS", category: "REPOSTERIA" },
    { group: "ALIMENTOS", category: "REPOSTERIA FRESCA" },
    { group: "ALIMENTOS", category: "SALSAS Y ADEREZOS" },
    { group: "ALIMENTOS", category: "SNACK SALUDABLES" },
    { group: "ALIMENTOS", category: "TABLAS DE QUESO CHARCUTERIA" },
    { group: "ALIMENTOS", category: "TORTA" },
    { group: "ALIMENTOS", category: "VARIOS CHARCUTERIA" },
    { group: "ASEO", category: "AMBIENTADORES" },
    { group: "ASEO", category: "ASEO BAÑO" },
    { group: "ASEO", category: "BLANQUEADORES CONVENCIONALES" },
    { group: "ASEO", category: "CERAS PARA PISO" },
    { group: "ASEO", category: "DESENGRASANTES Y MULTIUSOS" },
    { group: "ASEO", category: "DESMANCHADORES ROPA" },
    { group: "ASEO", category: "DETERGENTES" },
    { group: "ASEO", category: "EMPAQUE DE ALIMENTOS" },
    { group: "ASEO", category: "ESPECIALES CUIDADO DE LA ROPA" },
    { group: "ASEO", category: "ESTROPAJOS Y ACCESORIOS BAÑO" },
    { group: "ASEO", category: "IMPLEMENTOS ASEO HOGAR" },
    { group: "ASEO", category: "LAVA LOZAS" },
    { group: "ASEO", category: "LIMPIA PISOS" },
    { group: "ASEO", category: "OTRAS SUPERFICIES" },
    { group: "ASEO", category: "PAÑOS, ESPONJAS Y ESPONJILLAS" },
    { group: "ASEO", category: "RESTAURADORES MUEBLES Y ALFOMBRAS" },
    { group: "ASEO", category: "SERVILLETAS" },
    { group: "ASEO", category: "SUAVIZANTES" },
    { group: "ASEO", category: "TOALLAS DE COCINA" },
    { group: "CÁRNICOS", category: "CARNES ESPECIALES CHARCUTERIA" },
    { group: "CÁRNICOS", category: "CARNES FRIAS" },
    { group: "CÁRNICOS", category: "CARNES FRIAS CHARCUTERIA" },
    { group: "CÁRNICOS", category: "CARNES MADURADAS" },
    { group: "CÁRNICOS", category: "CERDO" },
    { group: "CÁRNICOS", category: "ENLATADOS CARNICOS Y COMIDAS PREPARADAS" },
    { group: "CÁRNICOS", category: "ESPARCIBLES CHARCUTERIA" },
    { group: "CÁRNICOS", category: "ESPARCIBLES REFRIGERADOS" },
    { group: "CÁRNICOS", category: "ESPECIALIDADES CHARCUTERIA" },
    { group: "CÁRNICOS", category: "OTRAS CARNES" },
    { group: "CÁRNICOS", category: "RES" },
    { group: "CÁRNICOS", category: "TAPAS CHARCUTERIA" },
    { group: "CUIDADO PERSONAL", category: "ACCESORIOS COSMETICOS Y PARA EL CABELLO" },
    { group: "CUIDADO PERSONAL", category: "AFEITADA Y DEPILACION" },
    { group: "CUIDADO PERSONAL", category: "AFEITADO" },
    { group: "CUIDADO PERSONAL", category: "BETUNES" },
    { group: "CUIDADO PERSONAL", category: "BOTIQUIN" },
    { group: "CUIDADO PERSONAL", category: "CREMAS NO LACTEAS" },
    { group: "CUIDADO PERSONAL", category: "CREMAS PARA MANOS Y CUERPO" },
    { group: "CUIDADO PERSONAL", category: "CUIDADO CORPORAL" },
    { group: "CUIDADO PERSONAL", category: "CUIDADO DEL BEBE" },
    { group: "CUIDADO PERSONAL", category: "CUIDADO ORAL" },
    { group: "CUIDADO PERSONAL", category: "CUIDADO Y PROTECCION DEL CABELLO" },
    { group: "CUIDADO PERSONAL", category: "DESODORANTES" },
    { group: "CUIDADO PERSONAL", category: "GEL ANTIBACTERIAL LIQUIDO" },
    { group: "CUIDADO PERSONAL", category: "JABONES DE TOCADOR" },
    { group: "CUIDADO PERSONAL", category: "JABONES DE TOCADOR Y ESTROPAJOS" },
    { group: "CUIDADO PERSONAL", category: "JABONES EN BARRA" },
    { group: "CUIDADO PERSONAL", category: "MAQUILLAJE" },
    { group: "CUIDADO PERSONAL", category: "PAÑALES ADULTO" },
    { group: "CUIDADO PERSONAL", category: "PAÑUELOS DESECHABLES" },
    { group: "CUIDADO PERSONAL", category: "PAPEL HIGIENICO" },
    { group: "CUIDADO PERSONAL", category: "PROTECCION FEMENINA" },
    { group: "CUIDADO PERSONAL", category: "PROTECCION SOLAR" },
    { group: "CUIDADO PERSONAL", category: "REPELENTES" },
    { group: "CUIDADO PERSONAL", category: "SALUD" },
    { group: "CUIDADO PERSONAL", category: "TALCOS" },
    { group: "CUIDADO PERSONAL", category: "TRATAMIENTOS CAPILARES" },
    { group: "CUIDADO PERSONAL", category: "TRATAMIENTOS FACIALES" },
    { group: "FERRETERÍA", category: "BOMBILLOS" },
    { group: "FERRETERÍA", category: "CARBON" },
    { group: "FERRETERÍA", category: "CUIDADO DEL VEHICULO" },
    { group: "FERRETERÍA", category: "ELECTRODOMESTICOS" },
    { group: "FERRETERÍA", category: "ELECTRODOMESTICOS MENORES" },
    { group: "FERRETERÍA", category: "FERRETERIA MENOR" },
    { group: "FERRETERÍA", category: "PILAS" },
    { group: "FERRETERÍA", category: "VEHICULOS" },
    { group: "FRUTAS", category: "FRUTAS" },
    { group: "FRUTAS", category: "FRUTOS Y SEMILLAS" },
    { group: "LICORES Y TABACO", category: "CERVEZAS" },
    { group: "LICORES Y TABACO", category: "CIGARRILLOS" },
    { group: "LICORES Y TABACO", category: "LICORES" },
    { group: "LICORES Y TABACO", category: "VAPEADORES" },
    { group: "LICORES Y TABACO", category: "VINOS" },
    { group: "OTROS", category: "ACCESORIOS BISUTERIA" },
    { group: "OTROS", category: "ACCESORIOS CABELLO" },
    { group: "OTROS", category: "ACCESORIOS CELULARES" },
    { group: "OTROS", category: "BONOS ELECTRONICOS" },
    { group: "OTROS", category: "CALCETINES" },
    { group: "OTROS", category: "CANASTILLAS" },
    { group: "OTROS", category: "CONCESION FLORISTERIA" },
    { group: "OTROS", category: "CONCESION RECAUDO BALOTO" },
    { group: "OTROS", category: "CONCESION TIENDA DEL CAFÉ" },
    { group: "OTROS", category: "CONCESION VENTA DE SOAT" },
    { group: "OTROS", category: "CONCESIONARIO GAFAS" },
    { group: "OTROS", category: "CONTROL PLAGAS" },
    { group: "OTROS", category: "CONTROL PLAGAS" },
    { group: "OTROS", category: "CORRESPONSAL BANCARIO" },
    { group: "OTROS", category: "DESECHABLES BLANCOS" },
    { group: "OTROS", category: "JUGETERIA PEREZ MEJIA" },
    { group: "OTROS", category: "JUGUETERIA" },
    { group: "OTROS", category: "JUGUETERIA PEREZ MEJIA" },
    { group: "OTROS", category: "LINEA TEXTIL" },
    { group: "OTROS", category: "MALETAS" },
    { group: "OTROS", category: "MASCOTAS" },
    { group: "OTROS", category: "NAVIDAD" },
    { group: "OTROS", category: "PARA CASA Y COCINA" },
    { group: "OTROS", category: "RECAUDO DESTINO JET" },
    { group: "OTROS", category: "RECAUDO EFECTY" },
    { group: "OTROS", category: "RECAUDO SEGUROS SOAT" },
    { group: "OTROS", category: "ROPA INTERIOR" },
    { group: "OTROS", category: "ROPA Y CALZADO" },
    { group: "OTROS", category: "SUBSIDIO COMFAMA" },
    { group: "OTROS", category: "TEMPORADA" },
    { group: "OTROS", category: "TOYS AND GIFT EU" },
    { group: "OTROS", category: "TRASPORTE DOMICILIOS" },
    { group: "OTROS", category: "VARIEDADES" },
    { group: "OTROS", category: "VARIEDADES MASCOTAS" },
    { group: "OTROS", category: "VARIEDADES TOYS" },
    { group: "OTROS", category: "VELAS, VELONES Y VELADORAS" },
    { group: "PAPELERÍA", category: "DECORACION" },
    { group: "PAPELERÍA", category: "ESCOLAR" },
    { group: "PAPELERÍA", category: "PIÑATERIA Y FIESTA" },
    { group: "PAPELERÍA", category: "REVISTAS Y PRENSA" },
    { group: "PAPELERÍA", category: "SUMINISTROS" },
    { group: "BOLSAS", category: "ICO BOLSA PLASTICA" },
    { group: "BOLSAS", category: "BOLSAS" },
    { group: "PESCADO", category: "ATUNES, SARDINAS Y ENLATADOS DE MAR" },
    { group: "PESCADO", category: "PESCADERIA FRESCA" },
    { group: "PESCADO", category: "PESCADOS Y MARISCOS" },
    { group: "POLLO", category: "PAVO" },
    { group: "POLLO", category: "POLLO" },
    { group: "VERDURAS", category: "VEGETALES CONGELADOS" },
    { group: "VERDURAS", category: "VEGETALES EN CONSERVA" },
    { group: "VERDURAS", category: "VERDURAS" },
]

import verdurasIcon from '/images/product_categories/VERDURAS.png';
import alimentosIcon from '/images/product_categories/ALIMENTOS.png';
import aseoIcon from '/images/product_categories/ASEO.png';
import carnicosIcon from '/images/product_categories/CARNICOS.png';
import cuidadoPersonalIcon from '/images/product_categories/CUIDADO PERSONAL.png';
import ferreteríaIcon from '/images/product_categories/FERRETERIA.png';
import frutasIcon from '/images/product_categories/FRUTAS.png';
import licoresyTabacoIcon from '/images/product_categories/LICORES Y TABACO.png';
import otrosIcon from '/images/product_categories/OTROS.png';
import papeleriaIcon from '/images/product_categories/PAPELERIA.png';
import bolsasIcon from '/images/product_categories/BOLSAS.png';
import pescadoIcon from '/images/product_categories/PESCADO.png';
import polloIcon from '/images/product_categories/POLLO.png';

export const categoriesImages = [
    { category: "VERDURAS", image: verdurasIcon },
    { category: "POLLO", image: polloIcon },
    { category: "ALIMENTOS", image: alimentosIcon },
    { category: "ASEO", image: aseoIcon },
    { category: "CÁRNICOS", image: carnicosIcon },
    { category: "CUIDADO PERSONAL", image: cuidadoPersonalIcon },
    { category: "FERRETERÍA", image: ferreteríaIcon },
    { category: "FRUTAS", image: frutasIcon },
    { category: "LICORES Y TABACO", image: licoresyTabacoIcon },
    { category: "OTROS", image: otrosIcon },
    { category: "PAPELERÍA", image: papeleriaIcon },
    { category: "BOLSAS", image: bolsasIcon },
    { category: "PESCADO", image: pescadoIcon },
    { category: "POLLO", image: polloIcon },
    { category: "VERDURAS", image: verdurasIcon },
]

