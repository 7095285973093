import { defineStore } from 'pinia';
import productImage from '/images/product_categories/OTROS.png';
// types
import type { InvoiceCreation, Invoice } from '@/types/apps/invoiceType';

export const useInvoiceStore = defineStore({
    id: 'invoice',
    state: () => ({
        invoiceId: '',
        paymentStatus: '',
        paymentInfo: {},
    }),
    getters: {
        getPaymentStatusName(state){
            switch (state.paymentStatus) {
                case 'approved': return 'Aprobada'; 
                case 'in_process': return 'En proceso'; 
                case 'rejected': return 'Rechazada'; 
                case null: 
                case '': return 'Sin iniciar pago';
            }
            return state.paymentStatus;
        },
        getPaymentInfo(state){
            return state.paymentInfo;
        }
    },
    actions: {
        // Fetch Customers from action
        async fetchInvoices(page?:string) {
            const baseUrl = useRuntimeConfig().public.apiBase;
            try {
                const response = await fetchWrapper.get(
                    `${baseUrl}/api/v1/management/invoices/my_invoices/${page}`, 
                );
                if (response){
                    return response
                }
            } catch (error) {
                // alert(error);
                return Promise.reject(error);
            }
        },
        async createInvoice(payload:InvoiceCreation) {
            const baseUrl = useRuntimeConfig().public.apiBase;
            try {
                const response = await fetchWrapper.post(
                    `${baseUrl}/api/v1/management/invoices/`, payload
                );
                if (response){
                    this.invoiceId = response.id.toString();
                    return response
                }
            } catch (error) {
                // alert(error);
                console.log(error);
                return Promise.reject(error);
            }
        },
        async initPaymentProcess(payload:any) {
            const baseUrl = useRuntimeConfig().public.apiBase;
            try {
                // const response = await fetchWrapper.patch(
                //     `${baseUrl}/api/v1/management/${payload.gateway}}/${this.invoiceId}/`, {
                //         "transaction_amount": payload.amount,
                //         "token": payload.id_customer_payment_gateway,
                //         "description": 'Snapei payment',
                //         "installments": payload.installments,
                //         "payment_method_id": payload.payment_method_id,
                //         "email": payload.email,
                //         "type": payload.,
                //         "number": payload.,
                //     }
                // );
                // if (response){
                //     return response
                // }
            } catch (error) {
                // alert(error);
                console.log(error);
                return Promise.reject(error);
            }
        },
        async updateInvoice(payload:object) {
            const baseUrl = useRuntimeConfig().public.apiBase;
            try {
                const response = await fetchWrapper.patch(
                    `${baseUrl}/api/v1/management/invoices/${this.invoiceId}/`, payload
                );
                if (response){
                    return response
                }
            } catch (error) {
                // alert(error);
                console.log(error);
                return Promise.reject(error);
            }
        },
        async setInvoice(invoice:string){
            this.invoiceId = invoice;
        },
        async setPaymentStatus(status:string){
            this.paymentStatus = status;
        },
        async setPaymentInfo(info:any){
            this.paymentInfo = info;
        }
    },
    persist: {
        storage: persistedState.sessionStorage,
    },
});
